@import "@material/react-list/index.scss";
@import "@material/react-menu-surface/index.scss";
@import "@material/react-menu/index.scss";
@import "@material/react-select/index.scss";

.Select {
  @include mdc-select-ink-color(var(--color-foreground-text));
  @include mdc-select-bottom-line-color(var(--color-foreground-text));
  @include mdc-select-container-fill-color(var(--color-background-card));
  @include mdc-select-label-color(var(--color-foreground-text));
  @include mdc-select-focused-label-color(var(--color-500));

  .mdc-select__dropdown-icon {
    color: var(--color-foreground-text);
  }

  .mdc-select__dropdown-icon {
    @include mdc-select-dd-arrow-svg-bg_(#ffffff, $mdc-select-dropdown-opacity);

    .mdc-select--focused & {
      @include mdc-select-dd-arrow-svg-bg_(#00add8, 1);
    }
  }
}

.mdc-menu .mdc-list {
  color: var(--color-foreground-text);
}
