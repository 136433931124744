.FixturePreview {
  display: flex;
  flex-direction: column;
  padding: 16px 48px 16px 60px;
  position: relative;
  width: 100%;

  & > .leftIcon {
    position: absolute;
    left: 14px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  & > h3 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
  }

  & > .details {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding-top: 4px;
    text-transform: uppercase;
    font-weight: medium;
  }

  & > .details > span:not(:last-of-type):after {
    content: "·";
    font-weight: bold;
    display: inline-block;
    margin: 0 6px;
  }

  .FixtureState {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  .FixtureStateIcon {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.Success {
      color: var(--color-contrast-500);
      background-color: var(--color-500);
    }

    &.Failure {
      color: var(--color-warn-contrast-500);
      background-color: var(--color-warn-500);
    }
  }
}
