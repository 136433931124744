.FixtureForm {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 200px;

  .StepperStepContent {
    display: block;
    position: relative;

    .alternative {
      display: block;
      color: var(--color-foreground-secondary-text);
      height: 36px;
      line-height: 36px;
      text-align: center;
      position: relative;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .alternative:before,
    .alternative:after {
      content: "";
      display: block;
      width: calc(50% - 125px);
      height: 1px;
      background-color: var(--color-foreground-secondary-text);
      position: absolute;
      top: 50%;
    }

    .alternative:before {
      left: 0;
    }

    .alternative:after {
      right: 0;
    }

    .StepActions {
      display: flex;
      flex-direction: row;
      padding: 18px 0 0;

      .Button:not(:first-of-type) {
        margin-left: 8px;
      }
    }
  }

  .error {
    display: block;
    color: var(--color-warn-contrast-500);
    background-color: var(--color-warn-500);
    padding: 8px;
    margin: 8px 0 0;
  }
}
