.QRCodeScanner {
  padding: 8px 0 0;
  position: relative;

  & > video {
    width: 100%;
    height: auto;
  }

  & > .QRCodeScannerHelper {
    width: 130px;
    height: 130px;
    position: absolute;
    border: 3px solid rgba(255, 255, 255, 0.5);
    top: 50%;
    margin-top: -65px;
    left: 50%;
    margin-left: -65px;
  }
}
