.ActionCard {
  padding: 14px;
  background-color: var(--color-900);
  color: var(--color-contrast-900);
  h2 {
    font-size: 17px;
    font-weight: 500;
    margin: 0;
  }

  p {
    line-height: 1.4;
    margin: 8px 0 12px;
  }

  button {
    background-color: var(--color-500);
    color: var(--color-contrast-500);
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 24px;
    border-radius: 2px;

    &:disabled {
      color: var(--color-300);
      opacity: 0.9;
    }
  }
}
