.SiteCard {
  background-color: var(--color-background-card);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  border-radius: 2px;
  display: grid;
  grid-template-areas:
    "icon name status"
    "icon details status";
  grid-template-columns: 56px 1fr 120px;

  h3,
  h4 {
    margin: 0;
    display: inline;
    font-weight: normal;
  }

  .icon {
    grid-area: icon;
    display: flex;
    align-items: center;
  }

  .name {
    grid-area: name;
  }

  .details {
    grid-area: details;

    h4 {
      font-size: 12px;
      text-transform: uppercase;

      &:not(:last-of-type) {
        margin-right: 12px;
      }

      em {
        font-weight: bold;
        font-style: normal;
        opacity: 0.82;
      }
    }
  }

  .status {
    grid-area: status;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
