.CreateFixturePanel {
  width: 100vw;
  height: 100vh;
  transition: transform 300ms ease-out, opacity 300ms ease-out;
  position: relative;
  z-index: 200;
  top: 0;
  left: 0;
  position: fixed;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--color-background-background);

  &.animate-enter,
  &.animate-exit-active {
    transform: translate3d(100vw, 0, 0);
    opacity: 0;
  }

  &.animate-enter-active,
  &.animate-exit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
