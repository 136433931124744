.Button {
  button {
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 14px 24px;
    border-radius: 2px;
    transition: color 300ms, opacity 300ms;

    &:disabled {
      color: var(--color-accent-200);
      opacity: 0.6;
    }
  }

  &.raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &.stroked button {
    box-shadow: none;
    background-color: transparent !important;
    border-width: 2px;
    border-style: solid;
  }

  &.dense button {
    font-size: 12px;
    font-weight: bold;
    padding: 8px 14px;
  }

  &.raised,
  &.flat {
    &.primary button {
      background-color: var(--color-500);
      color: var(--color-contrast-500);

      &:disabled {
        color: var(--color-primary-200);
      }
    }

    &.accent button {
      background-color: var(--color-accent-500);
      color: var(--color-accent-contrast-500);

      &:disabled {
        color: var(--color-accent-200);
      }
    }

    &.warn button {
      background-color: var(--color-warn-500);
      color: var(--color-warn-contrast-500);

      &:disabled {
        color: var(--color-warn-200);
      }
    }
  }

  &.stroked {
    &.primary button {
      border-color: var(--color-500);
      color: var(--color-500);

      &:disabled {
        color: var(--color-primary-200);
      }
    }

    &.accent button {
      border-color: var(--color-accent-500);
      color: var(--color-accent-500);

      &:disabled {
        color: var(--color-accent-200);
      }
    }

    &.warn button {
      border-color: var(--color-warn-500);
      color: var(--color-warn-500);

      &:disabled {
        color: var(--color-warn-200);
      }
    }
  }

  &.default button {
    background-color: var(--color-background-raised-button);
    color: var(--color-foreground-text);

    &:disabled {
      color: var(--color-foreground-secondary-200);
    }
  }
}
