.Stepper {
  display: block;
  padding-left: 46px;
}

.StepperStep {
  display: block;
  position: relative;
  margin: 8px 0 0;
  padding: 6px 0 32px;

  .StepperStepTitle {
    color: var(--color-foreground-secondary-text);
    font-size: 18px;
    display: block;
  }

  .StepperStepContent {
    padding: 8px 0 0;
    display: block;
  }

  &:before {
    content: "";
    display: block;
    width: 1px;
    position: absolute;
    top: 38px;
    left: -30px;
    bottom: 0;
    background-color: var(--color-foreground-secondary-text);
  }

  &:last-of-type:before {
    display: none;
  }

  &:after {
    content: attr(data-step);
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -46px;
    top: 0px;
    background-color: var(--color-background-card);
    color: var(--color-foreground-secondary-text);
  }

  &.Active,
  &.Visited {
    .StepperStepTitle {
      font-weight: 500;
      color: var(--color-foreground-text);
    }

    &:after {
      background-color: var(--color-500);
      color: var(--color-contrast-500);
    }
  }

  &.Visited {
    padding: 2px 0;

    .StepperStepContent {
      color: var(--color-foreground-secondary-text);
    }

    &:after {
      content: "✓";
    }
  }
}
