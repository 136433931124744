.SiteResults {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-background-background);
  z-index: 200;
}

.ResultScreen {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    padding: 0 60px;
    text-align: center;
    font-size: 26px;
  }

  &.primary {
    background-color: var(--color-500);
    color: var(--color-contrast-500);
  }

  &.warn {
    background-color: var(--color-warn-500);
    color: var(--color-contrast-500);
  }
}

.ErrorResultsScreenButtons {
  display: flex;
  flex-direction: column;

  button {
    background-color: var(--color-warn-900);
    color: var(--color-warn-contrast-900);
    border: 1px solid var(--color-warn-contrast-500);
    padding: 12px 34px;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 2px;
    margin: 0 0 8px;
  }

  button:disabled {
    opacity: 0.3;
  }
}
