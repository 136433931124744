.NavBar {
  display: block;
  background-color: var(--color-500);
  color: var(--color-contrast-500);
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  & > h1 {
    margin: 0;
    font-size: 22px;
    font-weight: medium;
  }
}

.ContentArea {
  height: calc(100vh - 60px);
  overflow-y: auto;
  position: relative;
}
