.InputWrapper {
  input {
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 16px;
    padding: 14px;
    color: var(--color-foreground-text);
    width: 100%;
    margin-top: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    transition: border-color 300ms;

    &:focus {
      border-color: var(--color-500);
    }
  }
}
