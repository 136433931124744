.Logo {
  height: 34px;
  width: auto;
  margin-right: 12px;
}

.FabSpacer {
  display: block;
  width: 100%;
  height: 80px;
}
