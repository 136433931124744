.ListLabel {
  display: block;
  background-color: var(--color-background-app-bar);
  width: 100%;
  padding: 10px 14px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 10;
}
