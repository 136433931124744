.SocialButtonsWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > img {
    margin-bottom: 18px;
  }
}

.SocialButtons {
  display: grid;
  grid-template-rows: 50px;
  row-gap: 8px;
}

.SocialButton {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  overflow: hidden;
  padding: 0 10px;
  user-select: none;
  width: 300px;
  position: relative;
  padding: 12px 12px 12px 60px;
  height: 50px;
  text-align: left;

  img {
    height: 26px;
    width: auto;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &.google {
    background-color: #bc4a30;
    color: #ffffff;
  }

  &.microsoft {
    background-color: #519ff5;
    color: #ffffff;
  }

  &.synapse {
    background-color: var(--color-500);
    color: var(--color-contrast-500);
  }
}
