.PageSpinner {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background-background);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .PageSpinnerContent {
    font-size: 24px;
    margin-top: 20px;
  }
}
