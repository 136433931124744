body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background-background);
  color: var(--color-foreground-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

/* Theming */
body {
  --color-50: #e0f5fa;
  --color-100: #b3e6f3;
  --color-200: #80d6ec;
  --color-300: #4dc6e4;
  --color-400: #26b9de;
  --color-500: #00add8;
  --color-600: #00a6d4;
  --color-700: #009cce;
  --color-800: #0093c8;
  --color-900: #0083bf;
  --color-A100: #e7f6ff;
  --color-A200: #b4e4ff;
  --color-A400: #81d2ff;
  --color-A700: #68c9ff;
  --color-contrast-50: #000000;
  --color-contrast-100: #000000;
  --color-contrast-200: #000000;
  --color-contrast-300: #000000;
  --color-contrast-400: #000000;
  --color-contrast-500: #000000;
  --color-contrast-600: #ffffff;
  --color-contrast-700: #ffffff;
  --color-contrast-800: #ffffff;
  --color-contrast-900: #ffffff;
  --color-contrast-A100: #000000;
  --color-contrast-A200: #000000;
  --color-contrast-A400: #000000;
  --color-contrast-A700: #000000;
  --color-accent-50: #fef2e6;
  --color-accent-100: #fcdec2;
  --color-accent-200: #fac899;
  --color-accent-300: #f8b170;
  --color-accent-400: #f7a151;
  --color-accent-500: #f59032;
  --color-accent-600: #f4882d;
  --color-accent-700: #f27d26;
  --color-accent-800: #f0731f;
  --color-accent-900: #ee6113;
  --color-accent-A100: #ffffff;
  --color-accent-A200: #fff0e9;
  --color-accent-A400: #ffcdb6;
  --color-accent-A700: #ffbc9c;
  --color-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-700: black;
  --color-accent-contrast-800: black;
  --color-accent-contrast-900: black;
  --color-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --color-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --color-warn-50: #fce8ea;
  --color-warn-100: #f6c5ca;
  --color-warn-200: #f19ea7;
  --color-warn-300: #eb7784;
  --color-warn-400: #e65a69;
  --color-warn-500: #e23d4f;
  --color-warn-600: #df3748;
  --color-warn-700: #da2f3f;
  --color-warn-800: #d62736;
  --color-warn-900: #cf1a26;
  --color-warn-A100: #ffffff;
  --color-warn-A200: #ffcfd2;
  --color-warn-A400: #ff9ca2;
  --color-warn-A700: #ff838a;
  --color-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-300: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-400: black;
  --color-warn-contrast-500: black;
  --color-warn-contrast-600: rgba(8, 5, 5, 0.87);
  --color-warn-contrast-700: rgba(255, 255, 255, 0.87);
  --color-warn-contrast-800: rgba(255, 255, 255, 0.87);
  --color-warn-contrast-900: rgba(255, 255, 255, 0.87);
  --color-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --color-warn-contrast-A400: black;
  --color-foreground-base: white;
  --color-foreground-divider: rgba(255, 255, 255, 0.12);
  --color-foreground-dividers: rgba(255, 255, 255, 0.12);
  --color-foreground-disabled: rgba(255, 255, 255, 0.5);
  --color-foreground-disabled-button: rgba(255, 255, 255, 0.3);
  --color-foreground-disabled-text: rgba(255, 255, 255, 0.5);
  --color-foreground-hint-text: rgba(255, 255, 255, 0.5);
  --color-foreground-secondary-text: rgba(255, 255, 255, 0.7);
  --color-foreground-icon: white;
  --color-foreground-icons: white;
  --color-foreground-text: white;
  --color-foreground-slider-min: white;
  --color-foreground-slider-off: rgba(255, 255, 255, 0.3);
  --color-foreground-slider-off-active: rgba(255, 255, 255, 0.3);
  --color-background-status-bar: black;
  --color-background-app-bar: #212121;
  --color-background-background: #303030;
  --color-background-hover: rgba(255, 255, 255, 0.04);
  --color-background-card: #424242;
  --color-background-dialog: #424242;
  --color-background-disabled-button: rgba(255, 255, 255, 0.12);
  --color-background-raised-button: #424242;
  --color-background-focused-button: rgba(255, 255, 255, 0.12);
  --color-background-selected-button: #212121;
  --color-background-selected-disabled-button: #424242;
  --color-background-disabled-button-toggle: black;
  --color-background-unselected-chip: #616161;
  --color-background-disabled-list-option: black;

  /**
   * Material Components for the Web
   */
  --mdc-theme-primary: var(--color-500);
  --mdc-theme-secondary: var(--color-accent-500);
  --mdc-theme-background: var(--color-background-background);
  --mdc-theme-surface: var(--color-background-card);
  --mdc-theme-on-primary: var(--color-contrast-500);
  --mdc-theme-on-secondary: var(--color-accent-contrast-500);
  --mdc-theme-on-surface: white;
}
