.TitleBar {
  display: block;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  & > h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
  }

  & > .TitleBarBackButton {
    padding: 0;
    position: relative;
    margin-right: 16px;
    top: 2px;
  }

  &.primary {
    background-color: var(--color-500);
    color: var(--color-contrast-500);
  }

  &.default {
    background-color: var(--color-background-app-bar);
    color: var(--color-foreground-text);
  }
}
