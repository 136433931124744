.Fab {
  display: block;
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: calc(100vw - 48px);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 50;

  & > button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    vertical-align: middle;
    background-color: var(--color-accent-500);
    color: var(--color-accent-contrast-500);
    border-radius: 60px;
    padding: 12px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: width 300ms;
  }

  & > button > .FabContent {
    padding-left: 10px;
    padding-right: 8px;
    font-size: 15px;
    text-transform: uppercase;
    transition: width 300ms, opacity 300ms, padding 300ms;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  &.FabMinimal > button {
    padding: 12px;
  }

  &.FabMinimal > button > .FabContent {
    width: 0;
    opacity: 0;
    padding: 0;
  }
}
